<template>
	<div class="showreels">
		<div class="header play-btn_wrap " @click="play('/static/video/LEE_SHOWREEL_v20201230_02_h264.mp4')">
			<img src="@/assets/images/showreels_header.png" alt="">
		</div>
		<div class="main">
			<p class="title animated fadeInUp">{{$t('lang.home.nav4')}}</p>
			<div class="video-box animated fadeInUp" v-for="(item,index) of list" :key="index">
				<div class="play-btn" @click="play(item.url)">
					<img class="cover" :src="item.cover" alt="">
				</div>
				<div class="right">
					<p>{{$t(item.name)}}</p>
					<img class="open-btn" @click="play(item.url)" src="@/assets/images/news/next.png" alt="">
				</div>
			</div>
		</div>
		<Player :url='url' />
	</div>
</template>

<script>
import Player from "@/components/Player.vue";
export default {
  name: "showreels",
  components: {
    Player
  },
  data() {
    return {
      url: "",
      list: [
        {
          url: "/static/video/LEE_SHOWREEL_v20201230_02_h264.mp4",
          cover: require("@/assets/images/cover_2.png"),
          name: "lang.showreels.text1"
        },
        {
          url: "/static/video/movie_trailer_h264.mp4",
          cover: require("@/assets/images/cover_1.png"),
          name: "lang.showreels.text2"
        }
      ]
    };
  },
  methods: {
    play(url) {
      if (url) {
        this.url = url;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.showreels {
  .play-btn {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.2);
        transition: all 2s;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 88px;
      height: 88px;
      background: url("../assets/images/play_btn_2.png") no-repeat center;
      background-size: 100%;
    }
  }
  .play-btn_wrap {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 88px;
      height: 88px;
      background: url("../assets/images/play_btn.png") no-repeat center;
      background-size: 100%;
    }
  }
  .main {
    width: 100%;
    margin: 0 auto;
    padding: 0 17.7vw 100px;

    p {
      text-align: left;
      font-size: 18px;
      opacity: 0.8;
      line-height: 30px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 32px;
      margin: 120px auto 30px;
      opacity: 1;
      font-family: "OpenSansLight";
    }
    .video-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      .cover {
        flex: 0 1 482px;
        width: 482px;
        height: 272px;
      }
      .right {
        flex: auto;
        padding-left: 80px;
        p {
          font-size: 26px;
          margin: 30px 0;
          text-align: left;
          opacity: 1;
          font-family: "OpenSansRegular";
        }
        .open-btn {
          display: block;
          width: 128px;
          height: 40px;
          opacity: 0.8;
          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>


<template>
	<div class="player-wrap" v-if="url">
		<video class="animated zoomIn" controls autoplay :src="url" id="player"></video>
		<img src="@/assets/images/menu_close.png" @click="$parent.url = ''" class="close-player" alt="">
		<img src="@/assets/images/full.png" @click="full" class="close-player full" alt="">
	</div>
</template>

<script>
export default {
  props: ["url"],
  mounted() {
    // this.player.play();
  },
  methods: {
    full() {
      let player = document.getElementById("player");
      console.log(player);
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.mozRequestFullScreen) {
        player.mozRequestFullScreen();
      } else if (player.msRequestFullscreen) {
        player.msRequestFullscreen();
      } else if (player.webkitRequestFullscreen) {
        player.webkitRequestFullScreen();
      }
    }
  },
  computed: {}
};
</script>

<style lang='scss'>
.player-wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #0b1e26;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    transform-origin: center;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    background: #0b1e26;
    height: 80%;
  }
  .close-player {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 20px;
    cursor: pointer;
  }
  .full {
    width: 18px;
    top: 41px;
    right: 80px;
  }
}
</style>
